import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Tabs.scss';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Button } from '~/components/atoms';
import { Style } from '~/components/atoms/button/Button';
import { sidebarColumns } from '~/utils/sidebarColumns';

export interface TabItemInterface {
  label: string;
  content: string;
  link?: string;
  buttonText?: string;
  sideContent?: string;
}

export interface TabInterface {
  items: TabItemInterface[];
  light?: boolean;
  side?: boolean;
  onChange?: (selectedTab: number) => void;
}

@Component({
  style,
})
export default class Tabs extends VueComponent<TabInterface>
  implements TabInterface {
  @Prop({ required: true, type: Array })
  public items!: TabItemInterface[];

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  protected rootClass: string = 'czt-tabs';
  protected selectedTab: number = 0;

  public render() {
    const classes: string[] = [this.rootClass];
    if (this.light) {
      classes.push(this.rootClass + '--light');
    }
    if (this.side) {
      classes.push(this.rootClass + '--side');
    }
    return (
      <v-tabs
        class={classes.join(' ')}
        onChange={this.handleChange}
        v-model={this.selectedTab}
        active-class={this.rootClass + '__tab--active'}
        show-arrows
      >
        <v-tabs-slider class={this.rootClass + '__slider'} />
        {this.items.map((item: TabItemInterface) => {
          return [
            <v-tab ripple={false} class={this.rootClass + '__tab'}>
              {item.label}
            </v-tab>,
            <v-tab-item class={this.rootClass + '__item'}>
              <v-row>
                <v-col class='pb-0'>
                  <HtmlRenderer content={item.content} light={this.light} />
                  {item.link && (
                    <div class={this.rootClass + '__item__link'}>
                      <Button
                        url={item.link}
                        buttonStyle={
                          this.light ? Style.PRIMARY_LIGHT : Style.PRIMARY
                        }
                      >
                        {item.buttonText
                          ? item.buttonText
                          : this.$t('app.common.exploreButton')}
                      </Button>
                    </div>
                  )}
                </v-col>
                {this.renderSideContent(item)}
              </v-row>
            </v-tab-item>,
          ];
        })}
      </v-tabs>
    );
  }

  @Emit('change')
  protected handleChange() {
    return this.selectedTab;
  }

  protected renderSideContent(item: TabItemInterface) {
    if (item.sideContent) {
      return (
        <v-col {...{ attrs: sidebarColumns }} class='pb-0'>
          <HtmlRenderer content={item.sideContent} light={this.light} side />
        </v-col>
      );
    }
  }
}
